import React from "react"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"
import { Flex, Card, Heading } from "rebass"
import Helmet from "react-helmet"

  const initSchema = ({brands, href}) => {
  let counter = 1
  const createBrandsSchema = brands.map(brand => {
    const item = {
      "@type": "Brand",
      url: href + '#' + brand.node.slug
    }
    if (brand.node.name) {
      item.name = brand.node.name
    }
    const temp = {
      "@type": "ListItem",
      position: counter,
      item
    }
    counter++
    return temp
  })

  return {
    "@context": "http://schema.org",
    "@type": "ItemList",
    name: "Car Brands in the Philippines",
    description: "List of all car brands in the country",
    itemListElement: createBrandsSchema,
  }
}

let brands, location;
export default class BrandList  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
    brands = this.props.brands;
    location = this.props.location;
  }
  componentDidMount() {
    this.setState({loading:false});
  }



  render() {
    const brandsSchema = initSchema({ brands, href: location.href })
     return (
        <>
          <Helmet>
            {
              !this.state.loading ? <script type="application/ld+json">{`${JSON.stringify(
               brandsSchema
             )}`}</script> : ''
            }

          </Helmet>
          <Flex flexWrap="wrap" justifyContent="space-between" p={["", "", "3rem"]}>
            {brands.map(brand => (
              <Card
                sx={{
                  borderRadius: "5px",
                }}
                width={["45%", "28%", "19%"]}
                m={2}
                key={brand.node.name}
                id={brand.node.slug}
              >
                <Link
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "inherit",
                  }}
                  to={`/brands/${brand.node.slug.trim()}`}
                >
                  <LazyLoad height={200} offset={240}>
                    <img
                      style={{
                        paddingBottom: "0",
                        height: "60px",
                      }}
                      alt={brand.node.name}
                      src={`/images/brands/${brand.node.name
                        .toLowerCase()
                        .trim()}.jpg`}
                    />
                  </LazyLoad>
                  <Heading> {brand.node.name}</Heading>
                </Link>
              </Card>
            ))}
          </Flex>
        </>
      )
    }
}
