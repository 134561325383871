import React from "react"

import Layout from "../../components/layout"
import { graphql } from "gatsby"
import BrandList from '../../components/brand-list/brand-list';
import {  Text, Heading } from "rebass";
export default class BrandIndexPage extends React.Component {
  render() {
    const brands = this.props.data.allContentfulBrand.edges;
    return (
      <Layout>
        <div>
          <div className="container" style={{ padding: "1rem" }}>
            <div className="row flex-column">
              <Heading textAlign="center" as="h1" color="primary">
                Car Brands in the Philippines
              </Heading>
              <Text fontSize="4" textAlign="center" fontWeight="bold" as="p">
                Select a brand below to view more models!
              </Text>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <BrandList location={this.props.location} brands={brands} />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const brandsQuery = graphql`
  query {
    allContentfulBrand {
      edges {
        node {
          name
          slug
          description {
          description
          }
        }
      }
    }
  }
`
